import { storeToRefs } from 'pinia'
import * as HostBrowserApis from '~/utils/host-browser-apis'
import { useNewsToggleStore } from '~/store/NewsToggle'
import { useThemeStore } from '~/store/ThemeStore'
import { getTypeTag as getTypeTagFromUrl } from '~/assets/js/utils'
import version from '~/package.json'

function waitForElement(selector: string, startingPoint = document) {
  return new Promise((resolve) => {
    if (startingPoint.querySelector(selector)) {
      return resolve(startingPoint.querySelector(selector))
    }

    const observer = new MutationObserver(() => {
      if (startingPoint.querySelector(selector)) {
        resolve(startingPoint.querySelector(selector))
        observer.disconnect()
      }
    })

    observer.observe(startingPoint.body ?? startingPoint, {
      childList: true,
      subtree: true,
    })
  })
}

async function getSource(type: string, parentId: string) {
  switch (type) {
    case 'TaboolaV5': {
      const parent = (await waitForElement('#' + parentId)) as Document
      const item = (await waitForElement('.branding', parent)) as HTMLElement
      const text = item?.innerText || item?.textContent
      return text
    }

    case 'Outbrain': {
      const parent = (await waitForElement('.' + parentId)) as Document
      const item = parent?.querySelector('.ob-rec-source') as HTMLElement
      const fullText = item?.innerText || item?.textContent
      const text = fullText?.split(' | AD')[0]
      return text
    }

    default: {
      return null
    }
  }
}

function getNtpPath(): string {
  const route = useRoute()
  return route.path
}

function getNtpUrl({ fullUrl = false }: { fullUrl?: boolean } = {}): string {
  if (fullUrl) {
    return window.location.href
  }
  return 'https://' + window.location.hostname + window.location.pathname
}

function findTypeTagInSearchUrl(url: string | null) {
  if (url == null) {
    return null
  }

  const pattern = '([0-9]{1}_[0-9]{4}_[0-9]{3}_[0-9]{4}_[0-9]{3}(_[0-9]{3})?_[0-9]{6})'
  const regexMatching = url.match(pattern)

  if (regexMatching != null) {
    return regexMatching[0]
  }

  return null
}

async function getTypeTag(): Promise<string> {
  const DEFAULT_TYPE_TAG = '0_1000_100_1000_100_691231'
  try {
    const installInfo = await HostBrowserApis.getConfig()
    if (installInfo?.type_tag) {
      return installInfo?.type_tag
    }
    const searchUrl = installInfo?.search_url || null
    const typeTagFromSearchUrl = findTypeTagInSearchUrl(searchUrl)
    if (typeTagFromSearchUrl) {
      return typeTagFromSearchUrl
    }
    const typeTagFromUrl = getTypeTagFromUrl()
    if (typeTagFromUrl) {
      return typeTagFromUrl
    }
    return DEFAULT_TYPE_TAG
  } catch (e) {
    return DEFAULT_TYPE_TAG
  }
}

function getNewsContentToggleState() {
  const newsToggleStore = useNewsToggleStore()
  const { newsToggle } = storeToRefs(newsToggleStore)
  return newsToggle.value ? 'on' : 'off'
}

function getCurrentThemeSetting() {
  const themeStore = useThemeStore()
  return themeStore.getMixpanelEventName
}

function getOneNewsVersion(): string {
  return version?.version || 'unknown'
}

export {
  getSource,
  getNtpPath,
  getNtpUrl,
  getCurrentThemeSetting,
  getTypeTag,
  getNewsContentToggleState,
  getOneNewsVersion,
}
