import { default as indexFyHbXg74EvMeta } from "/Users/caioamaralcorrea/Developer/onenews/pages/index.vue?macro=true";
import { default as _91id_93KDfc6Tec8YMeta } from "/Users/caioamaralcorrea/Developer/onenews/pages/v8/article/[id].vue?macro=true";
import { default as indextuoDjMaGA8Meta } from "/Users/caioamaralcorrea/Developer/onenews/pages/v8/index.vue?macro=true";
export default [
  {
    name: indexFyHbXg74EvMeta?.name ?? "index",
    path: indexFyHbXg74EvMeta?.path ?? "/",
    meta: indexFyHbXg74EvMeta || {},
    alias: indexFyHbXg74EvMeta?.alias || [],
    redirect: indexFyHbXg74EvMeta?.redirect,
    component: () => import("/Users/caioamaralcorrea/Developer/onenews/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93KDfc6Tec8YMeta?.name ?? "v8-article-id",
    path: _91id_93KDfc6Tec8YMeta?.path ?? "/v8/article/:id()",
    meta: _91id_93KDfc6Tec8YMeta || {},
    alias: _91id_93KDfc6Tec8YMeta?.alias || [],
    redirect: _91id_93KDfc6Tec8YMeta?.redirect,
    component: () => import("/Users/caioamaralcorrea/Developer/onenews/pages/v8/article/[id].vue").then(m => m.default || m)
  },
  {
    name: indextuoDjMaGA8Meta?.name ?? "v8",
    path: indextuoDjMaGA8Meta?.path ?? "/v8",
    meta: indextuoDjMaGA8Meta || {},
    alias: indextuoDjMaGA8Meta?.alias || [],
    redirect: indextuoDjMaGA8Meta?.redirect,
    component: () => import("/Users/caioamaralcorrea/Developer/onenews/pages/v8/index.vue").then(m => m.default || m)
  }
]